import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { NotificationService } from '../../../_services/notification.service';
import { SettingServices } from '../setting.services';
import { LocalService } from '../../../_services/local.service';
import { ExportExcelService } from '../../../_services/export-excel.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { CJVReasonModel } from '../setting.class';

@Component({
  selector: 'app-cjv-reason',
  templateUrl: './cjv-reason.component.html',
  styleUrls: ['./cjv-reason.component.scss']
})
export class CjvReasonComponent implements OnInit {

  public searchEvent = new Subject<any>();
    public searchSubcription: Subscription = null;
    public kGrid: KGridHelper = new KGridHelper();
    public deleting = false;
    public deletedDocId: number;
    public openDilog = false;
    public searchTerm = '';
    public openDilogConfirmation = false;
    public isExcelExport:boolean = false;
    public cjvReason = new CJVReasonModel();
  constructor(public _notificationService: NotificationService,
      public _settingService: SettingServices,
      public _localService: LocalService,
      private _exportToExcelService: ExportExcelService) { }

 ngOnInit() {
     this.initializeProps();
     this.getCJVReason();
   }
 
   private initializeProps() {
     this.kGrid.loading = false;
     this.kGrid.pageSize = 20;
     this.kGrid.pageSizes = [20, 50, 100];
   }
 
 
   public getCJVReason() {
     this._settingService.getCJVReason(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery, this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId, this.searchTerm).subscribe((res: any) => {
       debugger;
       if (res) {
         console.log(res);
         this.kGrid.data = [];
         this.kGrid.data = res.res;
         this.kGrid.totalRecords = res.tc;
         this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
         this.kGrid.loading = false;
       }
     }, err => {
       debugger;
 
       this._notificationService.notify("danger", err.msg);
 
     });
   }
   public OpenDileogForCJVReason() {
     this.openDilog = true;
   }
   public close(status) {
 
     this.openDilog = false;
   }
   public closeCat(status) {
     this.cjvReason = new CJVReasonModel();
     this.openDilog = false;
     this.openDilogConfirmation = false;
 
   }
  
   public onSubmit(status) {
 
     this.openDilog = false;
      this._settingService.saveCJVReason(this.cjvReason).subscribe((res: any) => {
 
       this._notificationService.notify("success", "Category Add Successfully");
        this.getCJVReason();
 
      }, err => {
 
        this._notificationService.notify("danger", err.msg);
 
      });
      this.cjvReason = new CJVReasonModel();
 
   }
 
 
   getBySearchTerm(e) {
     if (this.searchTerm !== ' ') {
        this._settingService.getCJVReason(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery, this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId,this.searchTerm).subscribe((res: any) => {
          if (res) {
            console.log(res);
            this.kGrid.data = [];
            this.kGrid.data = res.res;
            this.kGrid.totalRecords = res.tc;
            this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
            this.kGrid.loading = false;
          }
          }, (error) => {
          console.log(error);
        });
     } else {
       this.ngOnInit();
     }
   }
 
   public changePagesize(value: any) {
     this.kGrid.pageSize = +value;
     this.kGrid.skip = 0;
     this.getCJVReason();
   }
   public sortChange(sort: SortDescriptor[]): void {
     if (sort[0].field == 'asd') { return; }
     this.kGrid.sort = sort;
     this.sortData();
   }
   private sortData() {
     this.kGrid.gridView = {
       data: orderBy(this.kGrid.data, this.kGrid.sort),
       total: this.kGrid.totalRecords
     };
   }
   public pageChange(event: PageChangeEvent): void {
     this.kGrid.skip = event.skip;
     this.getCJVReason();
   }
   public RemoveCJVReason(e) {
     this.deletedDocId = e;
     this.openDilogConfirmation = true;
   }
 
   public ConfirmDelete() {
      this._settingService.removeCJVReason(this.deletedDocId).subscribe((res: any) => {
        this.deletedDocId = null;
        this.openDilogConfirmation = false;
        this._notificationService.notify("success", "Delete Successful");
 
        this.getCJVReason();
 
      }, err => {
        this._notificationService.notify("danger", err.error);
 
      });
   }
 
   
   exportToExcel(){
     this.isExcelExport = true;
     let excelArr=[];
   
     for(let i =0; i < this.kGrid.data.length; i++) {
       let obj ={
         Reason:this.kGrid.data[i].Reason,
         Description:this.kGrid.data[i].Description,
         CreateDate:this.kGrid.data[i].CreateDate
       }
       excelArr.push(obj);
     }
     this.isExcelExport = false;
     this._exportToExcelService.exportAsExcelFile(excelArr,'CJVReasons');
     console.log('new array',excelArr);
 }
 
 public EditCJVReason(x: any) {
   this.openDilog = true;
 
   let data: any = Object.assign({}, x);
    this.cjvReason = data;
   // console.log(this.catlog);
 
 }

}
