export class ClaimKeys {
  public STOREMANAGE = 'store.manage';
  public STOREINVENTORYMANAGE = 'store.inventory.manage';
  public STOREINVENTORYCATALOGMANAGE = 'store.inventory.catalog.manage';
  public STOREINVENTORYCATALOGVIEW = 'store.inventory.catalog.view';
  public STOREINVENTORYCATALOGCREATE = 'store.inventory.catalog.create';
  public STOREINVENTORYCATALOGEDIT = 'store.inventory.catalog.edit';
  public STOREINVENTORYCATALOGDELETE = 'store.inventory.catalog.delete';
  public STOREINVENTORYDEFINEITEMSMANAGE = 'store.inventory.define.items.manage';
  public STOREINVENTORYDEFINEITEMSVIEW = 'store.inventory.define.items.view';
  public STOREINVENTORYDEFINEITEMSCREATE = 'store.inventory.define.items.create';
  public STOREINVENTORYDEFINEITEMSEDIT = 'store.inventory.define.items.edit';
  public STOREINVENTORYDEFINEITEMSDELETE = 'store.inventory.define.items.delete';
  public STOREINVENTORYSTOCKASSIGNINGMANAGE = 'store.inventory.stock.assigning.manage';
  public STOREINVENTORYSTOCKASSIGNINGVIEW = 'store.inventory.stock.assigning.view';
  public STOREINVENTORYSTOCKASSIGNINGCREATE = 'store.inventory.stock.assigning.create';
  public STOREINVENTORYSTOCKASSIGNINGRETURN = 'store.inventory.stock.assigning.return';
  public STOREINVENTORYSTOCKASSIGNINGDELETE = 'store.inventory.stock.assigning.delete';
  public STOREINVENTORYRENTALVEHICLEMANAGE = 'store.inventory.rental.vehicle.manage';
  public STOREINVENTORYRENTALVEHICLERENTCREATEINVOICE = 'store.inventory.rental.vehicle.rent.invoice.create';
  public STOREINVENTORYRENTALVEHICLERENTCREATERECIEVE = 'store.inventory.rental.vehicle.rent.recieve.create';
  public STOREINVENTORYVEHICLERENTMANAGE = 'store.inventory.vehicle.rent.manage';
  public STOREINVENTORYVEHICLERENTVIEW = 'store.inventory.vehicle.rent.view';
  public STOREINVENTORYVEHICLERENTDELETE = 'store.inventory.vehicle.rent.delete';
  public STOREINVENTORYVEHICLERENTPROCEEDPAYMENT = 'store.inventory.vehicle.rent.proceed.payment';
  public STOREINVENTORYVEHICLERENTCREATERENT = 'store.inventory.vehicle.rent.create.rent';
  public STOREPURCHASEMANAGE = 'store.purchase.manage';
  public STOREPURCHASECREATE = 'store.purchase.create';
  public STOREPURCHASEVIEW = 'store.purchase.view';
  public STOREPURCHASEPROCEEDPAYMENT = 'store.purchase.proceed.payment';
  public STORERETURNMANAGE = 'store.return.manage';
  public STORERETURNVIEW = 'store.return.view';
  public STORERETURNCREATE = 'store.return.create';
  public STORESALEMANAGE = 'store.sales.manage';
  public STORESALEVIEW = 'store.sales.view';
  public STORESALEEDIT = 'store.sales.edit';
  public STORESALECREATE = 'store.sales.create';
  public STORESALEPROCEEDPAYMENT = 'store.sales.proceed.payment';
  public STOREREPORTSMANAGE = 'store.reports.manage';
  public STOREREPORTSINVENTORYVIEW = 'store.reports.inventory.view';
  public STOREREPORTSDEFINEDITEMVIEW = 'store.reports.defined.items.view';
  public STOREREPORTSASSIGNINGRETURNVIEW = 'store.reports.assiging.return.view';
  public STOREREPORTSRENTALVEHICLEVIEW = 'store.reports.rental.vehicle.view';
  public SETTINGSMANAGE = 'settings.manage';
  public SETTINGSACCOUNTHEADSMANAGE = 'settings.inventory.manage';
  public SETTINGSACCOUNTHEADSVIEW = 'settings.account.heads.view';
  public SETTINGSACCOUNTHEADSPREHEADVIEW = 'settings.account.heads.prehead.view';
  public SETTINGSACCOUNTHEADSPREHEADCREATE = 'settings.account.heads.prehead.create';
  public SETTINGSACCOUNTHEADSHEAD1VIEW = 'settings.account.heads.head1.view';
  public SETTINGSACCOUNTHEADSHEAD1CREATE = 'settings.account.heads.head1.create';
  public SETTINGSACCOUNTHEADSHEAD2VIEW = 'settings.account.heads.head2.view';
  public SETTINGSACCOUNTHEADSHEAD2CREATE = 'settings.account.heads.head2.create';
  public SETTINGSCATEGORYMANAGE = 'settings.category.manage';
  public SETTINGSCATEGORYVIEW = 'settings.category.view';
  public SETTINGSCATEGORYEDIT = 'settings.category.edit';
  public SETTINGSCATEGORYCREATE = 'settings.category.create';
  public SETTINGSCATEGORYDELETE = 'settings.category.delete';
  public SETTINGSNATUREMANAGE = 'settings.nature.manage';
  public SETTINGSNATUREVIEW = 'settings.nature.view';
  public SETTINGSNATURECREATE = 'settings.nature.create';
  public SETTINGSNATUREEDIT = 'settings.nature.edit';
  public SETTINGSNATUREDELETE = 'settings.nature.delete';
  public SETTINGSEXPENSEHEADMANAGE = 'settings.expense.head.manage';
  public SETTINGSEXPENSEHEADVIEW = 'settings.expense.head.view';
  public SETTINGSEXPENSEHEADCREATE = 'settings.expense.head.create';
  public SETTINGSEXPENSEHEADEDIT = 'settings.expense.head.edit';
  public SETTINGSEXPENSEHEADDELETE = 'settings.expense.head.delete';
  public SETTINGSVISAEXPENSEHEADMANAGE = 'settings.visa.expense.head.manage';
  public SETTINGSVISAEXPENSEHEADVIEW = 'settings.visa.expense.head.view';
  public SETTINGSVISAEXPENSEHEADCREATE = 'settings.visa.expense.head.create';
  public SETTINGSVISAEXPENSEHEADEDIT = 'settings.visa.expense.head.edit';
  public SETTINGSVISAEXPENSEHEADDELETE = 'settings.visa.expense.head.delete';
  public SETTINGSHEADERMANAGE = 'settings.header.manage';
  public SETTINGSHEADERVIEW = 'settings.header.view';
  public SETTINGSHEADERCREATE = 'settings.header.create';
  public SETTINGSHEADEREDIT = 'settings.header.edit';
  public SETTINGSHEADERDELETE = 'settings.header.delete';
  public ACCOUNTSMANAGE = 'accounts.manage';
  public ACCOUNTSCREATE = 'accounts.create';
  public ACCOUNTSVIEW = 'accounts.view';
  public ACCOUNTSDAYBOOKMANAGE = 'accounts.day.book.manage';
  public ACCOUNTSDAYBOOKVIEW = 'accounts.day.book.view';
  public ACCOUNTSDAYBOOKCREATE = 'accounts.day.book.create';
  public ACCOUNTSDAYBOOKEDIT = 'accounts.day.book.edit';
  public ACCOUNTSJOURNALVOUCHERMANAGE = 'accounts.journal.voucher.manage';
  public ACCOUNTSJOURNALVOUCHERVIEW = 'accounts.journal.voucher.view';
  public ACCOUNTSJOURNALVOUCHERCREATE = 'accounts.journal.voucher.create';
  public ACCOUNTSJOURNALVOUCHEREDIT = 'accounts.journal.voucher.edit';
  public ACCOUNTSJOURNALVOUCHERDELETE = 'accounts.journal.voucher.delete';
  public ACCOUNTSCATEGORICALJOURNALVOUCHERMANAGE = 'accounts.categorical.journal.voucher.manage';
  public ACCOUNTSCATEGORICALJOURNALVOUCHERVIEW = 'accounts.categorical.journal.voucher.view';
  public ACCOUNTSCATEGORICALJOURNALVOUCHERCREATE = 'accounts.categorical.journal.voucher.create';
  public ACCOUNTSCATEGORICALJOURNALVOUCHEREDIT = 'accounts.categorical.journal.voucher.edit';
  public ACCOUNTSCATEGORICALJOURNALVOUCHERDELETE = 'accounts.categorical.journal.voucher.delete';
  public ACCOUNTSCHEQUEMANAGE = 'accounts.cheque.manage';
  public ACCOUNTSCHEQUEISSUERECIEVE = 'accounts.cheque.issue.recieve';
  public ACCOUNTSCHEQUEISSUE = 'accounts.cheque.issue';
  public ACCOUNTSCHEQUERECIEVE = 'accounts.cheque.recieve';
  public ACCOUNTSCHEQUEADDBANK = 'accounts.cheque.add.bank';
  public ACCOUNTSREPORTSMANAGE = 'accounts.reports.manage';
  public ACCOUNTSREPORTSDAYBOOKVIEW = 'accounts.reports.daybook.view';
  public ACCOUNTSREPORTSLEDGERVIEW = 'accounts.reports.ledger.view';
  public ACCOUNTSREPORTSLEDGERWithOutBankVIEW = 'accounts.reports.ledgerwithoutbank.view';
  public ACCOUNTSREPORTSBALANCESHEETVIEW = 'accounts.reports.balance.sheet.view';
  public ACCOUNTSREPORTSINCOMESTATEMENTVIEW = 'accounts.reports.income.statement.view';
  public ACCOUNTSREPORTSCHARTOFACCOUNTSVIEW = 'accounts.reports.chart.of.accounts.view';
  public ACCOUNTSREPORTSCHARTOFHEADSVIEW = 'accounts.reports.chart.of.heads.view';
  public ACCOUNTSREPORTSTRIALBALANCEVIEW = 'accounts.reports.trial.balance.view';
  public EMPLOYEEMANAGE = 'employee.manage';
  public EMPLOYEECREATE = 'employee.create';
  public EMPLOYEEVIEW = 'employee.view';
  public EMPLOYEE_VIEW_EDIT = 'employee.view.edit';
  public EMPLOYEE_VIEW_DELETE = 'employee.view.delete';
  public EMPLOYEE_VIEW_CODES = 'employee.view.codes';
  public EMPLOYEE_VIEW_EMPLOYEE_STATUS = "employee.view.employee.status";
  public EMPLOYEE_VIEW_DISABLEENABLE = 'employee.view.disableenable';
  public EMPLOYEEASSIGNINGMANAGE = 'employee.assigning.manage';
  public EMPLOYEEASSIGNINGASSIGN = 'employee.assigning.assign';
  public EMPLOYEEASSIGNINGRETURN = 'employee.assigning.return';
  public EMPLOYEEASSIGNINGEDIT = 'employee.assigning.edit';
  public EMPLOYEESECURITYDEPOSITMANAGE = 'employee.security.deposit.manage';
  public EMPLOYEESECURITYDEPOSITCREATE = 'employee.security.deposit';
  public EMPLOYEEPETTYCASHCREATE = 'employee.petty.cash';
  public EMPLOYEESECURITYDEPOSITVIEW = 'employee.security.deposit';
  public EMPLOYEESECURITYDEPOSITRETURN = 'employee.security.deposit';
  public EMPLOYEEEARNINGSMANAGE = 'employee.earnings.manage';
  public EMPLOYEEEARNINGSPREVIOUSEARNINGSMANAGE = 'employee.earnings.previous.earnings.manage';
  public EMPLOYEEEARNINGSPREVIOUSEARNINGSEDIT = 'employee.earnings.previous.earnings.edit';
  public EMPLOYEEEARNINGSPREVIOUSEARNINGSVIEW = 'employee.earnings.previous.earnings.view';
  public EMPLOYEEEARNINGSPREVIOUSEARNINGSINVOICE = 'employee.earnings.previous.earnings.invoice';
  public EMPLOYEEEARNINGSPREVIOUSEARNINGSCREATE = 'employee.earnings.previous.earnings.create';
  public EMPLOYEEEARNINGSCOMMISSIONMANAGE = 'employee.earnings.commission.manage';
  public EMPLOYEEEARNINGSCOMMISSIONCREATE = 'employee.earnings.commission.create';
  public EMPLOYEEEARNINGSSALARYMANAGE = 'employee.earnings.salary.manage';
  public EMPLOYEEEARNINGSSALARYCREATE = 'employee.earnings.salary.create';
  public EMPLOYEEADVANCEMANAGEMENT = 'employee.advance.management';
  public EMPLOYEEADVANCECREATE = 'employee.advance.management';
  public EMPLOYEEVISASMANGE = 'employee.visas.manage';
  public EMPLOYEEVISASVIEW = 'employee.visas.view';
  public EMPLOYEEVISASCREATE = 'employee.visas.create';
  public EMPLOYEEVISAINSTALLMENTMANAGE = 'employee.visa.installment.manage';
  public EMPLOYEEVISAINSTALLMENTBULK = 'employee.visa.installment.bulk';
  public EMPLOYEEVISAINSTALLMENTVIEW = 'employee.visa.installment.view';
  public EMPLOYEEVISAINSTALLMENTEDIT = 'employee.visa.installment.edit';
  public EMPLOYEEVISAINSTALLMENTDELETE = 'employee.visa.installment.delete';
  public EMPLOYEEINSURANCESMANGE = 'employee.insurance.manage';
  public EMPLOYEEINSURANCESVIEW = 'employee.insurance.view';
  public EMPLOYEEINSURANCESEDIT = 'employee.insurance.edit';
  public EMPLOYEEINSURANCEINSTALLMENTMANAGE = 'employee.insurance.installment.manage';
  public EMPLOYEEINSURANCEINSTALLMENTBULK = 'employee.insurance.installment.bulk';
  public EMPLOYEEINSURANCEINSTALLMENTVIEW = 'employee.insurance.installment.view';
  public EMPLOYEEINSURANCEINSTALLMENTEDIT = 'employee.insurance.installment.edit';
  public EMPLOYEEINSURANCEINSTALLMENTDELETE = 'employee.insurance.installment.delete';
  public EMPLOYEEADVANCEMANAGE = 'employee.advance.manage';
  public EMPLOYEEADVANCEEDIT = 'employee.advance.edit';
  public EMPLOYEEADVANCEDELETE = 'employee.advance.delete';
  public EMPLOYEEADVANCEADD = 'employee.advance.add';
  public EMPLOYEEADVANCERETURN = 'employee.advance.return';
  public EMPLOYEEREPORTMANAGE = 'employee.report.manage';
  public EMPLOYEEREPORTCURRENTASSIGNING = 'employee.report.current.assigning';
  public EMPLOYEEREPORTCURRENTEMPLOYEE = 'employee.report.employee.report';
  public EMPLOYEEREPORTUNASSIGNEDEMPLOYEE = 'employee.report.unassigned.report';
  public EMPLOYEEREPORTPAYSLIP = 'employee.report.employee.payslip';
  public EXPENSEMANAGE = 'expense.manage';
  public EXPENSEREPORTS = 'expense.reports';
  public EXPENSEREPORTSTRAFFIC = 'expense.reports.traffic';
  public EXPENSEREPORTSEXPENSE = 'expense.reports.expense';
  public EXPENSESIMEXPENSEMANAGE = 'expense.sim.expense.manage';
  public EXPENSESIMEXPENSEVIEW = 'expense.sim.expense.view';
  public EXPENSESIMEXPENSEDETAILVIEW = 'expense.sim.expense.detail.view';
  public EXPENSESIMEXPENSECREATE = 'expense.sim.expense.create';
  public EXPENSESIMEXPENSEEDIT = 'expense.sim.expense.edit';
  public EXPENSESIMEXPENSEDELETE = 'expense.sim.expense.delete';
  public EXPENSEGENERALEXPENSEMANAGE = 'expense.general.expense.manage';
  public EXPENSEGENERALEXPENSEVIEW = 'expense.general.expense.view';
  public EXPENSEGENERALEXPENSEDETAILVIEW = 'expense.general.expense.detail.view';
  public EXPENSEGENERALEXPENSECREATE = 'expense.general.expense.create';
  public EXPENSEGENERALEXPENSEEDIT = 'expense.general.expense.edit';
  public EXPENSEGENERALEXPENSEDELETE = 'expense.general.expense.delete';
  public EXPENSETRAFFICEXPENSEMANAGE = 'expense.traffic.expense.manage';
  public EXPENSETRAFFICEXPENSEVIEW = 'expense.traffic.expense.view';
  public EXPENSETRAFFICEXPENSEDETAILVIEW = 'expense.traffic.expense.detail.view';
  public EXPENSETRAFFICEXPENSECREATE = 'expense.traffic.expense.create';
  public EXPENSETRAFFICEXPENSEEDIT = 'expense.traffic.expense.edit';
  public EXPENSETRAFFICEXPENSEDELETE = 'expense.traffic.expense.delete';
  public EXPENSETRAFFICEXPENSEPAYFINE = 'expense.traffic.expense.payfine';
  public CUSTOMERMANAGE = 'customer.manage';
  public CUSTOMERREPORTS = 'customer.reports';
  public CUSTOMERREPORTSCONTRACT = 'customer.reports.contract';
  public CUSTOMERREPORTSCASHRECEIVING = 'customer.reports.cash.receiving';
  public CUSTOMERCONTRACTMANAGE = 'expense.contract.manage';
  public CUSTOMERCONTRACTVIEW = 'expense.contract.view';
  public CUSTOMERCONTRACTDETAILVIEW = 'expense.contract.detail.view';
  public CUSTOMERCONTRACTDETAILVIEWADDDOCUMENT = 'expense.contract.detail.view.add.document';
  public CUSTOMERCONTRACTDETAILVIEWUSERACTIVE = 'expense.contract.detail.view.user.active';
  public CUSTOMERCONTRACTDETAILVIEWRETURNASSIGNEDEMPLOYEE = 'expense.contract.detail.view.return.assigned.employee';
  public CUSTOMERCONTRACTCREATE = 'expense.contract.create';
  public CUSTOMERCONTRACTEDIT = 'expense.contract.edit';
  public CUSTOMERCONTRACTDELETE = 'expense.contract.delete';
  public CUSTOMERCASHRECEIVINGMANAGE = 'expense.cash.receiving.manage';
  public CUSTOMERCASHRECEIVINGVIEW = 'expense.cash.receiving.view';
  public CUSTOMERCASHRECEIVINGCREATE = 'expense.cash.receiving.create';
  public CUSTOMERCASHRECEIVINGEDIT = 'expense.cash.receiving.edit';
  public CUSTOMERCASHRECEIVINGDELETE = 'expense.cash.receiving.delete';
  public CUSTOMERCASHRECEIVINGCASHRETURNVIEW = 'expense.cash.receiving.cash.return.view';
  public CUSTOMERCASHRECEIVINGCASHRETURNVIEWCREATE = 'expense.cash.receiving.cash.return.view.create';
  public SETTINGSACCOUNTHEADERS = 'settings.account.header';
  public SETTINGSCATEGORY = 'settings.category';
  public SETTINGSNATURE = 'settings.nature';
  public SETTINGSEXPENSEHEADS = 'settings.expense.heads';
  public SETTINGSVISAEXPENSE = 'settings.visa.expense';
  public EMPLOYEE_4PL_MANAGE = 'employee.4pl.manage';
  public EMPLOYEE_4PL_ASSIGN = 'employee.4pl.assign';
  public EMPLOYEE_4PL_RETURN = 'employee.4pl.return';
  public EMPLOYEE_4PL_EDIT = 'employee.4pl.edit';
  public EMPLOYEE_4PL_ASSIGN_EDIT = "employee.4pl.assign.edit"
  public EMPLOYEE_4PL_ASSIGN_DELETE = "employee.4pl.assign.delete"
  public EMPLOYEE_ASSIGNING_MANAGE = 'employee.assigning.manage';
  public EMPLOYEE_ASSIGNING_ASSIGN = 'employee.assigning.assign';
  public EMPLOYEE_ASSIGNING_RETURN = 'employee.assigning.return';
  public EMPLOYEE_ASSIGNING_EDIT = 'employee.assigning.edit';
  public EMPLOYEE_DHN_COMMISION_Delete = 'employee.dhn.commision.delete';
  public EMPLOYEE_DHN_COMMISION_VIEW = 'employee.dhn.commision.view';
  public EMPLOYEE_DHN_COMMISION_EDIT = 'employee.dhn.commision.edit';
  public EMPLOYEE_VISAS_EDIT = 'employee.visas.edit';
  public RENTAL_VEHICLE_EDIT = 'rental.vehicle.edit';
  public RENTAL_VEHICLE_DELETE = 'rental.vehicle.delete';
  public STORE_INVENTORY_RENT_INVOICES_EDIT = 'store.inventory.rent.invoice.create.edit';
  public STORE_INVENTORY_RENT_INVOICES_DELETE = 'store.inventory.rent.invoice.create.delete';
  public EMPLOYEE_TS_COMMISION_MANAGE = "employee.ts.commision.manage";
  public EMPLOYEE_TS_COMMISION_CREATE = "employee.ts.commision.create";
  public EMPLOYEE_TS_COMMISION_VIEW = "employee.ts.commision.view";
  public EMPLOYEE_TS_COMMISION_Delete = "employee.ts.commision.delete";
  public EMPLOYEE_TS_COMMISION_EDIT = "employee.ts.commision.edit";
  public REQUEST_TYPE_MANAGE = "request.type.manage";
  public REQUEST_TYPE_CREATE = "request.type.create";
  public REQUEST_TYPE_EDIT = "request.type.edit";
  public REQUEST_TYPE_DELETE = "request.type.delete";
  public REQUEST_CATEGORY_MANAGE = "request.category.manage";
  public REQUEST_CATEGORY_CREATE = "request.category.create";
  public REQUEST_CATEGORY_EDIT = "request.category.edit";
  public REQUEST_CATEGORY_delete = "request.category.delete";
  public REQUEST_MANAGE = "request.manage";
  public REQUEST_CREATE = "request.create";
  public REQUEST_DELETE = "request.delete";
  public DASHBOARD_CUSTOMER_CARD = "dashboard.customer.card";
  public DASHBOARD_UPCOMING_CHEQUE_CARD = "dashboard.upcoming.cheque.card";
  public DASHBOARD_ASSIGNED_EMPLOYEE_CARD = "dashboard.assigned.employee.card";
  public DASHBOARD_BIKE_INVENTORY_CARD = "dashboard.bike.inventory.card";
  public DASHBOARD_SIM_INVENTORY_CARD = "dashboard.sim.inventory.card";
  public DASHBOARD_INVENTORY_CARD = "dashboard.inventory.card";
  public DASHBOARD_ASSIGNED_INVENTORY_CARD = "dashboard.assigned.inventory.card";
  public DASHBOARD_RENTED_BIKE_CARD = "dashboard.rented.bike.card";
  public DASHBOARD_QUICK_LINK_CARD = "dashboard.quick.link.card";
  public DASHBOARD_PIE_CHART_CARD = "dashboard.pie.chart.card";
  public DASHBOARD_EMPLOYEE_CARD = "dashboard.employee.card";

  public SETTINGS_QUOTATION_NOTES_MANAGE = "settings.quotation.notes.manage";
  public SETTINGS_QUOTATION_NOTES_CREATE = "settings.quotation.notes.create";
  public SETTINGS_QUOTATION_NOTES_EDIT = "settings.quotation.notes.edit";
  public SETTINGS_QUOTATION_NOTES_DELETE = "settings.quotation.notes.delete";

  public STORE_QUOTATION_MANAGE = "store.quotation.manage";
  public STORE_QUOTATION_CREATE = "store.quotation.create";
  public STORE_QUOTATION_DELETE = "store.quotation.delete";

  public SETTINGS_SALESPERSON_MANAGE = "settings.salesperson.manage";
  public SETTINGS_SALESPERSON_CREATE = "settings.salesperson.create";
  public SETTINGS_SALESPERSON_EDIT = "settings.salesperson.edit";
  public SETTINGS_SALESPERSON_DELETE = "settings.salesperson.delete";

  public SETTINGS_ISDIRECTSALE_PREVIEW = "settings.isdirectsale.preview";

  public SETTINGS_CJVREASON_MANAGE = "settings.cjvreason.manage";
  public SETTINGS_CJVREASON_CREATE = "settings.cjvreason.create";
  public SETTINGS_CJVREASON_EDIT = "settings.cjvreason.edit";
  public SETTINGS_CJVREASON_DELETE = "settings.cjvreason.delete";
  }
