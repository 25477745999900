import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderFooter } from '../setting.class';
import { SettingServices } from '../setting.services';
import { NotificationService } from '../../../_services/notification.service';
import { LocalService } from '../../../_services/local.service';
import { FormCanDeactivate } from '../../../_guards/form-can-deactivate';
import { NgForm } from '@angular/forms';
import { KGridHelper } from '../../../_helpers/k-grid.class';

@Component({
  selector: 'app-header-footer',
  templateUrl: './header-footer.component.html',

})
export class HeaderFooterComponent extends FormCanDeactivate  implements OnInit {
  @ViewChild('f')
  form: NgForm;

  public obj: HeaderFooter = new HeaderFooter();
  public saving = false;
  public nowDate = new Date();
  public kGrid: KGridHelper = new KGridHelper();
  constructor(
    private settingServices: SettingServices,
    private _notificationService: NotificationService,
    private _localService: LocalService
  ) { super();  }

  ngOnInit() {
    this.getHeaderFooter();
  }
  public onSubmit() {
    this.saving = true;
    this.settingServices.saveHeaderFootrer(this.obj).subscribe((res: any) => {
      this.saving = false;
      this._notificationService.notify("success", "Save Successfully!");
      this.obj = new HeaderFooter();
      this.form.resetForm();
      this.getHeaderFooter();
    }, err => {
      this.saving = false;
      this._notificationService.notify("danger", err.error.msg);
    });
  }
  public attachfile(event) {
    this.obj.file = event.target.files[0];
  }
  public getHeaderFooter() {
    this.kGrid.loading = true;
    this.settingServices.GetHeaderFooterList().subscribe((res: any) => {
      if (res) {
        console.log('hedader footer',res);
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
        // this.obj = res;
      }
    }, err => {
      this.kGrid.loading = false;
      this._notificationService.notify("danger", err.error.msg);
    });
  }
  editHeaderFooter(obj){
   this.obj = obj;
  }
  primaryId:any;
  openComformDelete(id){
    debugger;
  this.primaryId = id;
  this.openDilogConfirmation = true;
  }
  removeHeaderFootrerLetterHead(){
    this.saving = true;
    this.settingServices.removeHeaderFootrerLetterHead(this.primaryId).toPromise().then((res:any) =>{
    this.saving = false;
    this.getHeaderFooter();
    this._notificationService.notify("success", "Deleted Successfully!");
    this.openDilogConfirmation = false;
    }).catch(error =>{
      this.saving = false;
      this._notificationService.notify("error", "Something went worng");
    });
  }
  openDilogConfirmation:boolean = false;
  closeDelete(){
    this.openDilogConfirmation = false;

  }
}
