import { publicDecrypt } from "crypto";
import { ItemViewModel } from "../store/store-model";

export class Heads {
  public Id: number
  public Name: string
  public Lvl: string
  public FK_ID: ItemViewModel
  public FK_ID_PH: ItemViewModel
}

export class VisaExpense {
  public Id: number;
  public Title: string;
  public Amount: number;
  public Fk_AccountDefination: ItemViewModel;

}
export class InsuranceExpense {
  public Id: number;
  public Title: string;
  public Amount: number;
  public Fk_AccountDefination: ItemViewModel;

}

export class Category {
  public Id: number
  public Name: string

}

export class Warehouse {
  public Id: number
  public Name: string

}

export class ExpenseHeadViewModel {
  public Id: number
  public Name: string
  public Fk_Category_Id: ItemViewModel;

}

export class HeaderFooter {
  public Id: number;
  public ComName: string;
  public Address: string;
  public contect: string;
  public footertext: string;
  public Attachment:string;
  public file: any;
}

export class Country{
  public Id: number;
  public CountryName: string;
  public Currency: string;
  public ISO: string;
  public CountryCode: string;
  public PhoneNumber: string;
  public Flag: string;
  public file: any;
}

export class Department{
  public Id : number;
  public Name : string;
}
export class AreaZoneViewModel{
  public Id: number;
  public Name: string;
  public ChargerPerOrder: number;
  public FuelChargePerOrder:number;
}
export class EmployeeStatusViewModel{
  public Id: number;
  public Name: string;
}
export class EmployeStatusChangeViewModel{
        public  Id :number;
        public  FK_Employe: ItemViewModel;
        public  FK_EmpStatus :ItemViewModel;
        public  Remarks :string;
}
export class Company{
  public Id : number;
  public Name : string;
  public  Title :string;
  public  BankName:string;
  public  BranchName :string
  public  AccountNumber :string;
  public  IBAN :string;
  public  TRNNumber :string;
  public  HeaderFooterId:number;
  public  HeaderFooter:string;
  public  InputVatAccountId:ItemViewModel;
  public  OutPutVatAccountId:ItemViewModel;
  public  VatRegistrationNumber :string;
}

export class FormRestrictionDefiningViewModel
    {
        public Id : number;
        public FK_FormId : ItemViewModel;
        public FormName : string;
        public StartDate : Date;
        public EndDate : Date;
        public Fk_ClientId : ItemViewModel;
        public todayDate: Date;
    }
      export class InventoryStatusViewModel
    {
        public  Id:number;
        public  Name:string;
        public  IsBasic:boolean;
    }
    export class E_Valid_Form
    {
        public Id : number;
        public Name : string;
    }
    export class CitiesViewModel
    {
        public Id:number; 
        public  Name : string;
    }
    export class CityChangeViewModel
    {
        public  FK_Item :ItemViewModel;
        public  FK_City: ItemViewModel;
        public CreatedDate:Date;
        public Remarks:string;
    }
export class DocumentsFormateViewModel {
  public  Id :number
  public  Code:string;
  public  Html:string
  public  Title :string;
}

export class QuotationNotesModel{
  public Id: number;
  public Note: string;
  public Comment: string;
  public CreatedBy: string;
  public CreationDate: string;
  public RecordStatus: boolean;
  public UserName: string;

}

export class QuotationSalesPersonModel{
  public Id: number;
  public Name: string;
  public Phone: string;
  public Email: string;
  public Description: string;
  public CreatedBy: string;
  public CreationDate: string;
  public RecordStatus: boolean;
  public UserName: string;

}

export class CJVReasonModel{
  public Id: number;
  public Reason: string;
  public Description: string;
  public Type: string;
  public CreatedBy: string;
  public CreationDate: string;
  public RecordStatus: boolean;
  public UserName: string;

}