import { Injectable } from '@angular/core';
import { Config } from '../../_helpers/config.class';
import { UserClaims, User } from '../user/user-claims.class';
import { HttpClient } from '@angular/common/http';
import { LocalService } from '../../_services/local.service';
import { FormRestrictionDefiningViewModel } from '../settings/setting.class';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient,
    private _localService: LocalService) { }
  // client
  public addOrUpdateClient(model: any) {
    this._localService.dateTransFuckDatesInObject(model);
    return this.http.post(`${Config.getControllerUrl('Client', 'AddOrUpdate')}`, model);
  }

  public ClientPaid() {
    return this.http.post(`${Config.getControllerUrl('Client', 'ClientPaid')}`, { Id: 0 });
  }

  public CheckClientBlock() {
    return this.http.post(`${Config.getControllerUrl('Client', 'CheckClientBlock')}`, { Id: 0 });
  }
  public GetClientList() {
    return this.http.get(`${Config.getControllerUrl('Client', 'Get')}`);
  }

  public deleteClient(id: number) {
    return this.http.post(`${Config.getControllerUrl('Client', 'Delete')}`, { Id: id });
  }

  public getFormList(){
    return this.http.get(`${Config.getControllerUrl('Account', 'GetFormRestrictionsList')}`);
  }

  public getFormRestrictionByFormId(id: number){
    return this.http.post(`${Config.getControllerUrl('Account', 'GetFormRestrictionsListByFormId')}`,{ Id: id });
  }

  public getFormNames(){
    return this.http.get(`${Config.getControllerUrl('Account', 'GetFormsNameList')}`);
  }

  public saveFormData(obj: FormRestrictionDefiningViewModel){
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Account', 'AddOrUpdateFormRestrictions')}`,formData);
  }

  public getClients() {
    return this.http.get(`${Config.getControllerUrl('Account', 'GetClients')}`);
  }
  public logout(){
    return this.http.post(`${Config.getControllerUrl('Account', 'Logout')}`,{ Id: 0 });
  }

}
